import React, {
  Fragment,
  FunctionComponent,
  ReactElement,
  useEffect,
  useState
} from "react";
import { Data, Events, Overrides } from "@transformd-ltd/sdk";
import { Helmet } from "react-helmet";
import {
  Formatic,
  CustomCheckboxList,
  CustomRadioList,
  Yeti
} from "../components";

const formProps = {
  apiServerUrl: "https://api-staging.transformd.com",
  serverUrl: "https://api-staging.transformd.com/graphql",
  subscriptionServerUrl: "wss://api-staging.transformd.com/subscriptions"
};

export type EmitterData = {
  fieldID: string;
  newValue: string | string[];
  oldValue: string | string[];
};

export type FormData = {
  [key: string]: any;
  getEmitter: () => any;
};

const Heading = ({ page, name }) => (
  <div className="space-y-8 text-center">
    <Helmet title="Insurance Company demo" defer={false} />
    {page === "624ce8c22b1d8500091c18c7" && (
      <Fragment>
        <h2 className="font-serif text-xl leading-tight lg:text-3xl lg:leading-10">
          I’ll get you an awesome price in minutes.
          <br />
          Ready to go?
        </h2>
        <p>Please write your name as it appears on your driver’s license</p>
      </Fragment>
    )}
    {page === "624ceede2b1d8500091c18df" && (
      <Fragment>
        <h2 className="font-serif text-xl leading-tight lg:text-3xl lg:leading-10">
          Great to meet you {name}! What's your home address?
        </h2>
      </Fragment>
    )}
    {page === "624d0ed32b1d85000731710c" && (
      <Fragment>
        <h2 className="font-serif text-xl leading-tight lg:text-3xl lg:leading-10">
          Do you rent or own it?
        </h2>
      </Fragment>
    )}
    {page === "624e0cce2b1d8500204cd192" && (
      <Fragment>
        <h2 className="font-serif text-xl leading-tight lg:text-3xl lg:leading-10">
          Are you a dog owner {name} 🐶?
        </h2>
      </Fragment>
    )}
    {page === "624e12702b1d8500215bae39" && (
      <Fragment>
        <h2 className="font-serif text-xl leading-tight lg:text-3xl lg:leading-10">
          Do you own any valuable jewelry, cameras, bikes or fine art {name}?
          <br />
          💍 📸 🚲 🖼
        </h2>
      </Fragment>
    )}
    {page === "624e1e162b1d85002426fa2b" && (
      <Fragment>
        <h2 className="font-serif text-xl leading-tight lg:text-3xl lg:leading-10">
          Do you currently have an active renters insurance policy {name}?
        </h2>
      </Fragment>
    )}
    {page === "624e215a2b1d8500221351c9" && (
      <Fragment>
        <h2 className="font-serif text-xl leading-tight lg:text-3xl lg:leading-10">
          Alright let's get you a quote {name}!
        </h2>
      </Fragment>
    )}
    {page === "624e2b2f2b1d85002426fa32" && (
      <Fragment>
        <h2 className="font-serif text-xl leading-tight lg:text-3xl lg:leading-10">
          Do you have any of the following at home {name}?
        </h2>
      </Fragment>
    )}
    {/* {page} */}
  </div>
);

const nonFields = ["heading", "richTextArea"];

const IndexPage: FunctionComponent = (): ReactElement => {
  const [page, setPage] = useState<string>("624ce8c22b1d8500091c18c7");
  const [name, setName] = useState<string>("");
  const [hasValuables, setHasValuables] = useState<string>("NO");
  const [status, setStatus] = useState<string>("base");
  const [data] = useState<FormData>(new Data());

  const order = ({
    dataForm,
    submission,
    currentPage,
    visibleFields,
    event
  }) => {
    const { fields, sections, pages } = dataForm;

    const pagesMap = pages
      ?.map(paged =>
        paged?.sections?.map(sectionLol => {
          const newFields = sections
            ?.filter(section => section.id === sectionLol.id)
            ?.map(section => {
              const newSection = section.fields.map(fieldLol => {
                const newFields = fields
                  ?.filter(field => field.id === fieldLol.id)
                  ?.filter(field => !nonFields.includes(field.renderer))
                  ?.map(field => ({
                    fieldId: field.id,
                    value: submission?.fieldsById?.[field?.id]?.value ?? null,
                    errors: visibleFields?.[field?.id]?.errors
                      ? Object.keys(visibleFields?.[field?.id]?.errors)
                      : []
                  }));

                return newFields;
              });
              return newSection;
            })
            ?.flat(2);

          return {
            page: paged?.attributes?.label,
            id: paged?.id,
            currentPage: paged?.id === page,
            fields: newFields
          };
        })
      )
      ?.flat()
      ?.filter(paged => paged?.fields?.length)
      ?.filter(paged => paged?.currentPage)
      ?.shift();

    const hasValues = pagesMap?.fields?.some(field => field?.value);
    const hasErrors = pagesMap?.fields?.some(field => field?.errors?.length);

    // console.log({ pagesMap, hasValues, hasErrors });

    if (!hasValues && !hasErrors) {
      return "base";
    }

    if (hasValues && !hasErrors) {
      if (event === "valueChanged") {
        return "validating";
      }
      return "success";
    }

    if (hasErrors) {
      return "error";
    }

    return "base";
  };

  const getValues = (newData, event) => {
    if (
      newData?.formatic?.dataForm?.pages?.length &&
      newData?.formatic?.data?.submission &&
      newData?.formatic?.visibleFields
    ) {
      const newStatus = order({
        dataForm: newData.formatic.dataForm,
        submission: newData.formatic.data.submission,
        visibleFields: newData.formatic.visibleFields,
        currentPage: page,
        event
      });
      return setStatus(newStatus);
    }
    return setStatus("base");
  };

  const inputChangeListener = () => {
    const emitter = data?.getEmitter();

    // Listen to page render events and set id of current page
    emitter.on(Events.PageRender, page => {
      const renderData = data?.store?.getState();
      setPage(page.id);
      getValues(renderData, "pageRender");
    });

    // Listen to field change events and update state of some values (name,valuables)
    emitter.on(Events.ValueChanged, (emitterData: EmitterData) => {
      const valueChangedData = data?.store?.getState();
      if (emitterData.fieldID === "624ce9f92b1d8500091c18d3") {
        setName(emitterData?.newValue?.toString());
      }
      if (emitterData.fieldID === "624e12d62b1d85001f01a916") {
        setHasValuables(emitterData?.newValue?.toString());
      }
      getValues(valueChangedData, "valueChanged");
    });

    emitter.on(Events.FieldValidated, () => {
      const validationData = data?.store?.getState();
      getValues(validationData, "fieldValidated");
    });

    emitter.on(Events.FieldError, () => {
      const errorData = data?.store?.getState();
      getValues(errorData, "fieldError");
    });
  };

  useEffect(() => {
    inputChangeListener();
  }, [page]);

  return (
    <div className="min-h-screen space-y-16 overflow-x-hidden bg-[url('/img/background/sydney.png')] bg-contain bg-fixed bg-clip-border bg-bottom bg-no-repeat bg-origin-border">
      <div className="relative flex h-24 items-center border-b px-4 lg:h-16">
        <h1 className="h-14 font-serif text-2xl lg:h-auto lg:text-3xl">
          Insurance <span className="text-pink-600">Company</span>
        </h1>
        <div className="absolute right-1/2 top-full inline-block h-28 w-28 translate-x-1/2 -translate-y-1/2 transform overflow-hidden rounded-full border-4 border-gray-600">
          <Yeti status={status} />
        </div>
      </div>
      <div className="lemonade font-sans">
        <div className="mx-auto max-w-xl space-y-8 rounded-3xl bg-white/[.8] p-4 backdrop-grayscale">
          <div className="mx-auto max-w-2xl">
            <Heading page={page} name={name} />
          </div>
          <div className="mx-auto max-w-lg">
            <Formatic
              {...formProps}
              data={data}
              formId={524}
              apiKey="afdcDf159365F9d9A6d12E334be147562A97F2B2b3a46D6A15F0cF611240Ab63"
              environment="Staging"
              channel="master"
              initialValues={{}}
              config="default"
              theme="transformd"
            >
              <Overrides.OverrideFieldContainer
                component={CustomCheckboxList}
                type="checkboxList"
              />
              <Overrides.OverrideFieldContainer
                component={CustomRadioList}
                type="radioList"
              />
            </Formatic>
          </div>
          {hasValuables === "YES" && page === "624e12702b1d8500215bae39" && (
            <div className="inline-flex items-center space-x-2 rounded-3xl bg-white/[.8] p-2">
              <img
                className="inline-block h-10 w-10 rounded-full"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <p className="rounded-3xl bg-teal-500 px-5 py-2 text-white">
                OK, I'll show you how to add them to your policy in a bit.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default IndexPage;
